.list {
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.list::-webkit-scrollbar {
  display: none;
}
