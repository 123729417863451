/* select.css-lsp5xe option[value] {
  display: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0;
} */

.css-12rdf6g, [data-css-12rdf6g] {
  text-align: left !important;
}
