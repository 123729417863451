@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;200;300;400;500;600&display=swap");

* {
  font-family: "Be Vietnam Pro", sans-serif !important;
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter";
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.MuiBox-root.css-1vjgoe {
  object-fit: contain;
  width: 100%;
  margin-bottom: 16px;
  object-fit: cover;
  height: auto;
  margin-top: -10px;
}

.inputNewPassword,
.inputConfirmPassword {
  outline: none;
}

.MuiFormControl-root {
  width: 100%;
}

#composition-list-label {
  font-size: 13px;
}

@media (min-width: 480px) {
  .label {
    font-size: 8px !important;
  }
}

@media (max-width: 419px) {
  .css-1wnsr1i {
    padding: 15px !important;
    width: 320px !important;
  }
}

@media (max-width: 480px) {
  .db {
    display: none;
  }
}

@media (max-width: 900px) {
  .productListImg {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

@media (max-width: 915px) {
  .name {
    display: none;
  }
}

