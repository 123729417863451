.main {
  display: flex;
  margin-bottom: 0.75em;
}

.sidebar div {
  font-size: 14px;
  font-weight: 600;
}

.title {
  font-size: 3em;
}

.sidebar {
  flex-shrink: 0;
  background-color: #fff;
  height: 100%;
  max-height: 100vh;
  max-width: 285px;
  overflow-x: hidden;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-item {
  padding: 0.75em 1em;
  display: block;
  border-radius: 5px;
}

.sidebar-title {
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
}
.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
  color: #000;
}
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
  position: relative;
  top: -10px;
}
#open {
  transition: transform 0.3s;
  transform: rotate(180deg);
  cursor: pointer;
}
.sidebar-content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  color: #000;
  text-decoration: none;
}
.sidebar-item.plain:hover {
  text-decoration: underline;
}
.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}

/* .main,
.sidebar-name > span:first-child {
  font-size: 14px;
  font-weight: 600;
} */

.sidebar-content > .main {
  font-size: 14px;
  font-weight: 400;
}
.bi-chevron-down {
  cursor: pointer;
  position: relative;
  top: -10px;
}

.cancel {
  margin-top: 50px;
  position: relative;
  left: 80%;
}

.cancel svg {
  background-color: rgba(116, 116, 128, 0.08);
  border-radius: 50%;
}
