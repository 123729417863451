.slider-container {
  width: 100%;
  position: relative;
}

/* Style each slide */
.slide {
  display: none;
  width: 100%;
  height: 100%;
}

.photo {
  object-fit: contain;
  width: 90%;
  margin: auto;
  display: block;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  height: 100px;
  /* background: rgba(0, 0, 0, 0.3); */
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 50px);
}

/* Position the "next" button to the right */
.next {
  right: 5%;
}

.prev {
  left: 5%;
}

/* On hover, add a semi-transparent black background */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.caption {
  position: absolute;
  bottom: 8px;
  width: 100%;
  padding: 8px 12px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

/* The dots indicator */
.dots {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 15px;
}

.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  transition: 0.5s;
}

.active,
.dot:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .prev,
  .next {
    height: 50px;
    top: calc(50% - 25px);
  }
}
